import {
  ModalContent,
  ModalRoot,
  ModalTrigger,
} from '@synoptic/ui-kit/modal/modal.js';
import React, { useState } from 'react';
import { NewPostForm } from './form.tsx';
import { useAddPost } from './use-add-post.tsx';
import { RegularPostInput } from '@/__generated__/graphql.ts';

export type NewPostModalProps = {
  trigger: React.ReactNode;
};

export const NewPostModal: React.FC<NewPostModalProps> = ({ trigger }) => {
  const [open, onOpenChange] = useState(false);
  const [addPost] = useAddPost();

  const onSubmit = async (input: RegularPostInput) => {
    await addPost(input);
    onOpenChange(false);
  };

  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <ModalTrigger asChild>{trigger}</ModalTrigger>
      <ModalContent>
        <NewPostForm
          withHidden={true}
          onSubmit={(input) => onSubmit(input as RegularPostInput)}
        />
      </ModalContent>
    </ModalRoot>
  );
};
